.switcherCol{
    padding-left: 0em !important;
    padding-right: 0em !important;
}

.ui.inverted.button {
    box-shadow: none !important;
}

.ui.inverted.button:hover {
    box-shadow: none !important;
}