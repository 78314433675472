.address:hover{
    cursor: pointer;
}

.ui.modal {
    text-align: center !important;
}
.ui.modal > .actions {
    text-align: center !important;
}

#innerDimmer{
    position: unset !important;
}